<template>
  <div class="Mobcontainer MobmainSection MobBlueBg">
    <div id="MobfullCornerLogo">
      <img src="../assets/images/logo_white.webp" id="MobcornerLogoImg" alt="LogoIcon">
      <pre class="MoblogoText">Second<br>Chapter</pre>
    </div>
    <div class="MobmainContainer">
      <h1 class="Mobh1">Book trading,<br>made easy.</h1>
      <!--
      <form  action="send-email.php" method="POST">
        <div class="inputBox" style="background-color:white">
          <input type="email" placeholder="Email address" name="email" required>
          <button type="submit" value="Send">Submit</button>
        </div>
      </form>
    -->
      <div class="Mobstore-buttons">
        <!-- app store download -->
        <a href="#" class="Mobstore-button">
          <img src="../assets/images/app-store-small.webp" alt="App Store" class="Mobstore-logo">
          <p class="MobdownloadText">Coming Soon</p>
        </a>
        <!-- google play store download -->
        <a href="#" class="Mobstore-button">
          <img src="../assets/images/google-play-small.webp" alt="Google Play" class="Mobstore-logo">
          <p class="MobdownloadText">Coming Soon</p>
        </a>
      </div>
    </div>
  </div>
  <div class="Mobcontainer MobpitchRow MobWhiteBg">
    <div class="MobpitchText">
      <h2 class="Mobh2">What is <br>Second Chapter?</h2>
      <p class="Mobp">Second Chapter is a mobile app that makes buying and selling textbooks a breeze. Our platform is developed with
        students and parents in mind, providing a hassle-free and cost-effective way to trade
        in your textbooks.</p>
      <img src="../assets/images/logo.webp" id="MoblogoImg"  alt="LogoIcon">
    </div>
  </div>


  <hr />
  <div class="Mobcontainer MobpitchRow MobwhiteBg">
    <div class="MobpitchText">
      <h2 class="Mobh2">Second Chapter @ Schools</h2>
      <p class="Mobp">Our platform seamlessly integrates into your school's technology ecosystem, 
        creating an internal market for buying and selling second-hand textbooks. 
        Personalize it based on your school's specific booklists, empowering 
        students and parents to save money and be part of a sustainable solution.</p>
        <a href="mailto: daranewsome01@gmail.com"><button class="button">CONTACT US TO LEARN MORE</button></a>

    </div>


  </div>
  <div class="Mobcontainer MobfeatureMainContent GreyBg">
    <h2 class="Mobh2">How we Stand Out</h2>

    <!-- condition claffication feature -->
    <div class="MobfeatureRow">
      <div class="MobfeatureText">
        <h3 class="Mobh3">Condition Classification</h3>
        <p class="Mobp">We use machine learning to accurately grade the condition of a
          seller's textbook</p>
      </div>
      <video class="MobfeatureImgTop" alt="Traffic Lights" autoplay muted loop>
        <source src="../assets/images/traffic.webm" type="video/mp4">
      </video>
    </div>

    <!-- scan to sell feature -->
    <div class="MobfeatureRow">
      <video class="MobfeatureImgBottom" alt="Barcode Scanner" autoplay muted loop>
        <source src="../assets/images/scanner.webm" type="video/mp4">
      </video>     
      <div class="MobfeatureText">
        <h3 class="Mobh3">Scan-to-sell</h3>
        <p class="Mobp">Scan the ISBN of
          your books to create new listings.</p>
      </div>
    </div>
  </div>
  <div class="MobBlueBg">
    <div class="Mobcontainer MobtestimonialSection">
      <div>
        <h2 id="MobReviewText">What People are Saying</h2>
      </div>
      <div class="MobquotationsRow">

        <div class="MobquotationsColumn">
          <img src="../assets/images/quote.webp" class="Mobquotation" alt="Quotation marks">
          <div>
            <p class="Mobtestimonial">Second Chapter is a game-changer. Selling textbooks has never been easier and I can buy
              for a fair price.</p>
          </div>
          <div>
            <p class="Mobtestimonee">Conor Glynn</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="https://www.instagram.com/second.chapter/"><img src="../assets/images/instagram.png" class="footerIMG" alt="Instagram"></a>
    <a href="mailto: daranewsome01@gmail.com"><img src="../assets/images/email.png" class="footerIMG" alt="Email"></a>
    <a href="https://www.linkedin.com/company/second-chapter-books-ie/"><img src="../assets/images/linkedin.png" class="footerIMG" alt="LinkedIn"></a>
  </div>
</template>












<script>
export default {
  name: ' MobileComponent'
}
</script>
<style src="../assets\css\mobile.css"></style>