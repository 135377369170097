<template>
  <div v-if="!isMobile()">
    <main-component></main-component>
  </div>
  <div v-else>
    <mobile-component>
    </mobile-component>
  </div>
</template>

<script>
import MainComponent from './components/main.vue'
import MobileComponent from './components/mobile.vue'

export default {
  name: 'App',
  components: {
    'main-component': MainComponent,
    'mobile-component': MobileComponent,
  },
  
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}

</script>
