<template>
  <div class="container mainSection blueBg">
    <!-- corner logo -->
    <div id="fullCornerLogo">
      <img src="../assets/images/logo_white.webp" id="cornerLogoImg" alt="LogoIcon">
      <pre class="logoText">Second<br>Chapter</pre>
    </div>
    <div class="mainRow">
    <!-- app preview -->
    <img src="../assets/images/mockupWithPagesSmall.webp" class="floating" alt="phoneRender" id="longBlo">

    <div class="mainContainer">
      <h1>Book trading,<br>made easy</h1>

      <div class="store-buttons">
        <!-- app store download -->
        <a href="#" class="store-button">
          <img src="../assets/images/app-store-small.webp" alt="App Store" class="store-logo">
          <p class="downloadText">Coming Soon</p>
        </a>
        <!-- google play store download -->
        <a href="#" class="store-button">
          <img src="../assets/images/google-play-small.webp" alt="Google Play" class="store-logo">
          <p class="downloadText">Coming Soon</p>
        </a>
      </div>

    </div>
  </div>
  </div>

  <!-- start of pitch section -->
  <div class="container pitchRow whiteBg">
    <div class="pitchText">
      <h2>What is <br>Second Chapter?</h2>
      <p>Second Chapter is a mobile app that makes buying and selling textbooks a breeze. Our platform is developed with
        students and parents in mind, providing a hassle-free and cost-effective way to trade
        in your textbooks.</p>
    </div>

    <img src="../assets/images/logo.webp" id="logoImg" alt="LogoIcon">
  </div>
  <!-- end of pitch section -->

  <!-- start of 4schools section -->
  <hr />
  <div class="container pitchRow whiteBg">
    <div class="pitchText">
      <h2>Second Chapter @ Schools</h2>
      <p>Our platform seamlessly integrates into your school's technology ecosystem, 
        creating an internal market for buying and selling second-hand textbooks. 
        Personalize it based on your school's specific booklists, empowering 
        students to save money and be part of a sustainable solution.</p>
        <a href="mailto: daranewsome01@gmail.com"><button class="button">CONTACT US TO LEARN MORE</button></a>

    </div>


  </div>
  <!-- end of 4schools section -->

  <!-- start of key-features section -->
  <div class="container featureMainContent greyBg">
    <h2>How we Stand Out</h2>

    <!-- condition claffication feature -->
    <div class="featureRow">
      <div class="featureText">
        <h3>Condition Classification</h3>
        <p>Our condition classifier uses machine learning to accurately grade the condition of a
          seller's textbook; ensuring you can buy with confidence.</p>
      </div>
      <video class="featureImg" alt="Traffic Lights" autoplay muted loop>
        <source src="../assets/images/traffic.webm" type="video/mp4">
      </video>
    </div>

    <!-- scan to sell feature -->
    <div class="featureRow">
      <video class="featureImg" id="featureImgLeft"  alt="Barcode Scanner" autoplay muted loop>
        <source src="../assets/images/scanner.webm" type="video/mp4">
      </video>
      <div class="featureText">
        <h3>Scan-to-sell</h3>
        <p>Second Chapter enables you to sell books with ease. Through our streamlined process, you can scan the ISBN of
          your books to create new listings.</p>
      </div>
    </div>

  </div>
  <!-- end of key-features section -->

  <div class="blueBg">
    <div class="container testimonialSection">
      <div class="ReviewPos">
        <h2 id="ReviewText">What People are Saying</h2>
      </div>
      <div class="quotationsRow">

        <div class="quotationsColumn">
          <img src="../assets/images/quote.webp" alt="Quotation" class="quotation">
          <div>
            <p class="testimonial">Second Chapter is a game-changer. Selling textbooks has never been easier and I can buy
              for a fair price. I love the app's user-friendly design!</p>
          </div>
          <div>
            <p class="testimonee">Conor Glynn</p>
          </div>
        </div>
        <div class="quotationsColumn">
          <img src="../assets/images/quote.webp" alt="Quotation" class="quotation">
          <div>
            <p class="testimonial">Very cool idea!</p>
          </div>
          <div>
            <p class="testimonee">Michael Foley</p>
          </div>
        </div>

        <div class="quotationsColumn">
          <img src="../assets/images/quote.webp" alt="Quotation" class="quotation">
          <div>
            <p class="testimonial">As a college student, I'm always looking for ways to save money. Second Chapter has
              made buying and selling textbooks so easy and affordable.</p>
          </div>
          <div>
            <p class="testimonee">Art O Liathain</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- start of footer section -->
  <div class="container footerRow greyBg">
    <a href="https://www.instagram.com/second.chapter/"><img src="../assets/images/instagram.png" class="socialImg" alt="Instagram"></a>
    <a href="mailto: daranewsome01@gmail.com"><img src="../assets/images/email.png" class="socialImg" alt="Email"></a>
    <a href="https://www.linkedin.com/company/second-chapter-books-ie/"><img src="../assets/images/linkedin.png" class="socialImg" alt="LinkedIn"></a>
  </div>
</template>


<script>
export default {
  name: ' MainComponent'
}
</script>
<style src="../assets\css\common.css"></style>

